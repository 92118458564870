/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIsAccessAllow } from "../../../../../app/modules/users/redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const isUserListAllow = useIsAccessAllow("user.list");
  const isContactListAllow = useIsAccessAllow("contact_us.list");
  const isGetAQuoteListAllow = useIsAccessAllow("get_a_quote.list");
  const isGenerativeAIListAllow = useIsAccessAllow("get_genAI_quote.list");
  const isCareersListAllow = useIsAccessAllow("careers.list");
  const isBlogListAllow = useIsAccessAllow("blog.list");
  const isTagListAllow = useIsAccessAllow("tag.list");
  const isCategoryListAllow = useIsAccessAllow("category.list");
  const isAuthorListAllow = useIsAccessAllow("authorlist");
  const isEmailManagementListAllow = useIsAccessAllow("email_management.list");
  const isMailSubscribersListAllow = useIsAccessAllow("mail_subscribers.list");
  const isDubaiBlogListAllow = useIsAccessAllow("dubai_blog.list");
  const isDubaiTagListAllow = useIsAccessAllow("dubai_tag.list");
  const isDubaiCategoryListAllow = useIsAccessAllow("dubai_category.list");
  const isHireAIBlogListAllow = useIsAccessAllow("hire_ai_blog.list");
  const isHireAITagListAllow = useIsAccessAllow("hire_ai_tag.list");
  const isHireAICategoryListAllow = useIsAccessAllow("hire_ai_category.list");
  const isDubaiAuthorListAllow = useIsAccessAllow("dubai_author.list");
  const isHireAIAuthorListAllow = useIsAccessAllow("hire_ai_author.list");

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {isUserListAllow && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/user-management",
              true
            ) ||
              getMenuItemActive("/user", true) ||
              getMenuItemActive("/role", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/user">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">User Management</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {isUserListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive("/user", false)}`}
                  >
                    <NavLink className="menu-link" to="/user">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Group.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Users</span>
                    </NavLink>
                  </li>
                )}

                {/* <li className={`menu-item ${getMenuItemActive("/role", false)}`}>
                <NavLink className="menu-link" to="/role">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Contact1.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Role</span>
                </NavLink>
              </li> */}
              </ul>
            </div>
          </li>
        )}
        {/* 

        {/* Contact Us */}
        {isContactListAllow && (
          <li
            className={`menu-item ${getMenuItemActive("/contact-us", false)}`}
          >
            <NavLink className="menu-link" to="/contact-us">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Contact Us</span>
            </NavLink>
          </li>
        )}

        {/* Quote Us */}
        {isGetAQuoteListAllow && (
          <li className={`menu-item ${getMenuItemActive("/quote", false)}`}>
            <NavLink className="menu-link" to="/quote">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Get A Quote</span>
            </NavLink>
          </li>
        )}

        {/* Generative AI */}
        {/* {isGenerativeAIListAllow && (
          <li
            className={`menu-item ${getMenuItemActive("/generativeAI", false)}`}
          >
            <NavLink className="menu-link" to="/generativeAI">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Generative AI</span>
            </NavLink>
          </li>
        )}*/}

        {/* Careers */}
        {isCareersListAllow && (
          <li className={`menu-item ${getMenuItemActive("/careers", false)}`}>
            <NavLink className="menu-link" to="/careers">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Careers</span>
            </NavLink>
          </li>
        )}

        {(isBlogListAllow ||
          isTagListAllow ||
          isCategoryListAllow ||
          isAuthorListAllow) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/blog",
              true
            ) ||
              getMenuItemActive("/tag", true) ||
              getMenuItemActive("/category", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {
              <NavLink className="menu-link menu-toggle" to="/blog">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Contact1.svg"
                    )}
                  />
                </span>
                <span className="menu-text">WebClues Blog</span>
                <i className="menu-arrow" />
              </NavLink>
            }

            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {isBlogListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive("/blog", false)}`}
                  >
                    <NavLink className="menu-link" to="/blog">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Blog</span>
                    </NavLink>
                  </li>
                )}

                {isTagListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive("/tag", false)}`}
                  >
                    <NavLink className="menu-link" to="/tag">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Tag</span>
                    </NavLink>
                  </li>
                )}

                {isCategoryListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/category",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/category">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Category</span>
                    </NavLink>
                  </li>
                )}
                {isAuthorListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/category",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/author">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Author</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* ------------------------- Ster Dubai Blog -------------------------- */}
        {(isDubaiBlogListAllow ||
          isDubaiTagListAllow ||
          isDubaiCategoryListAllow ||
          isDubaiAuthorListAllow) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dubai_blog",
              true
            ) ||
              getMenuItemActive("/dubai_tag", true) ||
              getMenuItemActive("/dubai_author", true) ||
              getMenuItemActive("/dubai_category", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {
              <NavLink className="menu-link menu-toggle" to="/dubai_blog">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Contact1.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Dubai Blog</span>
                <i className="menu-arrow" />
              </NavLink>
            }

            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {isDubaiBlogListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/dubai_blog",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/dubai_blog">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Blog</span>
                    </NavLink>
                  </li>
                )}

                {isDubaiTagListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/dubai_tag",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/dubai_tag">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Tag</span>
                    </NavLink>
                  </li>
                )}

                {isDubaiCategoryListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/dubai_category",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/dubai_category">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Category</span>
                    </NavLink>
                  </li>
                )}
                {isDubaiAuthorListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/dubai_author",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/dubai_author">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Author</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* ------------------------- End Dubai Blog -------------------------- */}

        {/* ------------------------- Start Hire AI Blog -------------------------- */}
        {(isHireAIBlogListAllow ||
          isHireAITagListAllow ||
          isHireAICategoryListAllow ||
          isHireAIAuthorListAllow) && (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/hire_ai_blog",
              true
            ) ||
              getMenuItemActive("/hire_ai_tag", true) ||
              getMenuItemActive("/hire_ai_author", true) ||
              getMenuItemActive("/hire_ai_category", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {
              <NavLink className="menu-link menu-toggle" to="/hire_ai_blog">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Contact1.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Hire AI Blog</span>
                <i className="menu-arrow" />
              </NavLink>
            }

            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {isHireAIBlogListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/hire_ai_blog",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/hire_ai_blog">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Blog</span>
                    </NavLink>
                  </li>
                )}

                {isHireAITagListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/hire_ai_tag",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/hire_ai_tag">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Tag</span>
                    </NavLink>
                  </li>
                )}

                {isHireAICategoryListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/hire_ai_category",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/hire_ai_category">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Category</span>
                    </NavLink>
                  </li>
                )}
                {isHireAIAuthorListAllow && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/hire_ai_author",
                      false
                    )}`}
                  >
                    <NavLink className="menu-link" to="/hire_ai_author">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Contact1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Author</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* ------------------------- End Hire AI Blog -------------------------- */}

        {isEmailManagementListAllow && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/email-management",
              false
            )}`}
          >
            <NavLink className="menu-link" to="/email-management">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Email Management</span>
            </NavLink>
          </li>
        )}

        {isMailSubscribersListAllow && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/mail-subscribers",
              false
            )}`}
          >
            <NavLink className="menu-link" to="/mail-subscribers">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Mail Subscribers</span>
            </NavLink>
          </li>
        )}
        <li
          className={`menu-item ${getMenuItemActive(
            "/whatsapp/accounts",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/whatsapp/accounts">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Contact1.svg"
                )}
              />
            </span>
            <span className="menu-text">Whatsapp Accounts</span>
          </NavLink>
        </li>

        {/* Page Speed Score */}
        {isContactListAllow && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/page-speed-score",
              false
            )}`}
          >
            <NavLink className="menu-link" to="/page-speed">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Contact1.svg"
                  )}
                />
              </span>
              <span className="menu-text">Page Speed Score</span>
            </NavLink>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
